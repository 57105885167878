import {Action, Dispatch} from "redux";
import {searchProductsService} from "../book/services/SearchProductsService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {Product} from "../entity/Product";

export const callSearchProducts = (search: string | undefined, productPropertyFilterIds: string[] | undefined, categories: string[] | undefined, releaseType: number | undefined) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateSearchProductsStatusActionBuilder(ProcessStatus.InProgress));

        searchProductsService(getState().user.token as string, {
            search,
            productPropertyFilterIds,
            categories,
            releaseType,
            page: getState().searchProducts.pagination.currentPage,
            pageSize: getState().searchProducts.pagination.pageSize
        })
            .then((json) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("SearchProducts error code:", json.error);
                    dispatch(updateSearchProductsStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("SearchProducts error: empty payload");
                    dispatch(updateSearchProductsStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateSearchProductsStateBuilder(
                    json.payload.products,
                    json.payload.count,
                    json.executionTime
                ));
            })
            .catch((err: any) => {
                console.error("Generic searchProducts error:", err);
                dispatch(updateSearchProductsStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const UpdateSearchProductsStatusActionName = 'UpdateSearchProductsStatusAction';

export interface UpdateSearchProductsStatusAction extends Action {
    status: ProcessStatus;
}

export const updateSearchProductsStatusActionBuilder = (status: ProcessStatus): UpdateSearchProductsStatusAction => ({
    type: UpdateSearchProductsStatusActionName,
    status
});

// Update state
export const UpdateSearchProductsStateActionName = 'UpdateSearchProductsStateAction';

export interface UpdateSearchProductsStateAction extends Action {
    foundProducts: Product[];
    count: number;
    executionTime: number;
    status: ProcessStatus;
}

export const updateSearchProductsStateBuilder = (foundProducts: Product[], count: number, executionTime: number, status: ProcessStatus=ProcessStatus.Success): UpdateSearchProductsStateAction => ({
    type: UpdateSearchProductsStateActionName,
    foundProducts: foundProducts,
    count,
    executionTime,
    status
});