import {PaginatedRequest} from "../../entity/PaginatedRequest";
import {ServiceResponse} from "../../entity/ServiceResponse";
import {User} from '../../entity/User';
import {Settings} from "../Settings";
import {fetchService} from "../FetchService";

export const searchUsersService = (authenticationToken: string, request: SearchUsersRequest, abortController?: AbortController): Promise<ServiceResponse<SearchUsersResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/user', {
        method: 'POST',
        signal: abortController?.signal,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface SearchUsersResponse {
    users: User[];
    count: number;
}

export interface SearchUsersRequest extends PaginatedRequest {
    search: string;
}