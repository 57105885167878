import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {AppConfiguration} from "../entity/AppConfiguration";
import {updateAppConfigurationsService} from "../book/services/UpdateAppConfigurationsService";

export const updateAppConfigurationsAction = (configurations:AppConfiguration[]) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setUpdateAppConfigurationStatusActionBuilder(ProcessStatus.InProgress));

        updateAppConfigurationsService(getState().user.token as string, { configurations })
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("AppConfigurationManagement error code:", json.error);
                    dispatch(setUpdateAppConfigurationStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setUpdateAppConfigurationStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setUpdateAppConfigurationStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetUpdateAppConfigurationStatusActionName = 'SetUpdateAppConfigurationStatusAction';

export interface SetUpdateAppConfigurationStatusAction extends Action {
    status: ProcessStatus;
}

export const setUpdateAppConfigurationStatusActionBuilder = (status: ProcessStatus): SetUpdateAppConfigurationStatusAction => ({
    type: SetUpdateAppConfigurationStatusActionName,
    status,
});