import {Settings} from "../Settings";
import {ServiceResponse} from "../../entity/ServiceResponse";
import {fetchService} from "../FetchService";

export const deleteUserService = (authenticationToken: string, request: DeleteUserRequest): Promise<ServiceResponse<DeleteUserResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/user', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface DeleteUserRequest {
    userId: string;
}

export interface DeleteUserResponse {
}