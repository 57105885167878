import {Action} from "redux";
import {
    UpdateMeStateAction,
    UpdateMeStateActionName,
    UpdateMeStatusAction,
    UpdateMeStatusActionName
} from "../action/MeAction";
import {UserState} from "../entity/state/UserState";
import {initialAppState} from '../store/InitialAppState';
import {ToUserRole} from '../entity/UserRole';
import {ToggleDrawerSizeActionName} from "../action/ToggleDrawerSizeAction";
import {
    UpdateLoginStateAction,
    UpdateLoginStateActionName,
    UpdateLoginStatusAction,
    UpdateLoginStatusActionName
} from "../action/LoginAction";
import {SessionStorage} from "../book/SessionStorage";
import {SessionStorageKey} from "../book/SessionStorageKey";
import {ResetUserStateActionName} from "../action/ResetUserStateAction";

export const userReducer = (state: UserState = initialAppState.user, a: Action): UserState => {
    switch (a.type) {
        case UpdateMeStatusActionName:
            const updateMeStatusAction = a as UpdateMeStatusAction;
            return {
                ...state,
                meStatus: updateMeStatusAction.status
            };
        case UpdateMeStateActionName:
            const updateMeStateAction = a as UpdateMeStateAction;
            return {
                ...state,
                username: updateMeStateAction.username,
                role: ToUserRole(updateMeStateAction.role),
                meStatus: updateMeStateAction.status
            };
        case ToggleDrawerSizeActionName:
            return {
                ...state,
                expandedMenu: !state.expandedMenu
            };
        case UpdateLoginStatusActionName:
            const updateLoginStatusAction = a as UpdateLoginStatusAction;
            return {...state, loginStatus: updateLoginStatusAction.loginStatus};
        case UpdateLoginStateActionName:
            const updateLoginStateAction = a as UpdateLoginStateAction;

            SessionStorage.setInt(SessionStorageKey.expiredAt, updateLoginStateAction.expiredAt);
            SessionStorage.setString(SessionStorageKey.token, updateLoginStateAction.token);

            return {
                ...state,
                expiredAt: updateLoginStateAction.expiredAt,
                token: updateLoginStateAction.token,
                loginStatus:updateLoginStateAction.loginStatus
            };
        case ResetUserStateActionName:
            SessionStorage.removeItem(SessionStorageKey.expiredAt);
            SessionStorage.removeItem(SessionStorageKey.token);

            return initialAppState.user;
    }

    return state;
}