import {Action} from "redux";

export const SetSearchProductsPaginationActionName = "SetSearchProductsPaginationActionName";

export interface SetSearchProductsPaginationAction extends Action {
    currentPage: number,
    pageSize: number,
}

export const setSearchProductsPaginationActionBuilder = (currentPage: number, pageSize: number): SetSearchProductsPaginationAction => {
    return {
        type: SetSearchProductsPaginationActionName,
        currentPage,
        pageSize
    }
}