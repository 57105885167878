import {Action} from "redux";
import {
    UpdateGetProductStateAction,
    UpdateGetProductStateActionName,
    UpdateGetProductStatusAction,
    UpdateGetProductStatusActionName
} from "../action/GetProductAction";
import {initialAppState} from "../store/InitialAppState";
import {ProductDetailsState} from '../entity/state/ProductDetailsState';

export const productDetailsReducer = (state: ProductDetailsState = initialAppState.productDetails, a: Action): ProductDetailsState => {
    switch (a.type) {
        case UpdateGetProductStatusActionName:
            return {
                ...state,
                status: (a as UpdateGetProductStatusAction).status
            };
        case UpdateGetProductStateActionName:
            const updateSearchProductsStateAction = a as UpdateGetProductStateAction;
            return {
                ...state,
                product: updateSearchProductsStateAction.product,
                status: updateSearchProductsStateAction.status
            };
    }

    return state;
}