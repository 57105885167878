import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {RoutingContainer} from './component/routing/RoutingContainer';
import './index.css';
import {appStore} from './store/AppStore';
import {I18nextProvider} from "react-i18next";
import i18n from "./book/i18n";
import React from 'react';
import {Suspense} from 'react';
import {SplashScreen} from "./component/common/loading/SplashScreen";

const theme = createTheme({
    palette: {
        primary: {
            main: '#FE0000',
        },
        secondary: {
            main: '#FE0000',
        },
    },
});

ReactDOM.render(
    <Provider store={appStore}>
        <ThemeProvider theme={theme}>
            <Suspense fallback={<SplashScreen/>}>
                <I18nextProvider i18n={i18n}>
                    <RoutingContainer/>
                </I18nextProvider>
            </Suspense>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);