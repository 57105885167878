import React, {FunctionComponent} from "react";
import Paper from "@material-ui/core/Paper";
import {Loading} from "./Loading";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import {CarelImage} from "../carellogo/CarelImage";

export const useStyles = makeStyles(theme => createStyles({
    loader: {
        margin: "0px auto",
        marginTop: "80px",
        paddingTop: theme.spacing() * 2,
        paddingBottom: theme.spacing() * 2,
        width: "90%",
        maxWidth: "600px",
        overflow: "hidden",
        textAlign: 'center'
    },
    loading: {
        marginTop: '20px'
    }
}));

export const SplashScreen: FunctionComponent = () => {
    const classes = useStyles();

    return <div>
        <AppBar>
            <Toolbar>
                <CarelImage/>
            </Toolbar>
        </AppBar>
        <Paper className={classes.loader}>
            <img src={"carel-logo.png"} alt="Carel logo"/>
            <Loading className={classes.loading}/>
        </Paper>
    </div>;
};