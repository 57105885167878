import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {fetchService} from "../FetchService";
import {ValueCount} from "../../entity/ValueCount";

export const monitoringProductMonthChartService = (authenticationToken: string, request: MonitoringProductMonthChartRequest): Promise<ServiceResponse<MonitoringProductMonthChartResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/monitoring/product-by-month', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface MonitoringProductMonthChartRequest {
}

export interface MonitoringProductMonthChartResponse {
    items: ValueCount[];
}

