import {Action, Dispatch} from "redux";
import {SearchUsersRequest, SearchUsersResponse, searchUsersService} from "../book/services/SearchUsersService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {User} from '../entity/User';
import {evaluateErrorAction} from "./EvaluateErrorAction";

export const callSearchUsers = (search: string, page?: number | null, pageSize?: number | null) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateUserStatusActionBuilder(ProcessStatus.InProgress));

        searchUsersService(getState().user.token as string, {search, page, pageSize} as SearchUsersRequest)
            .then((json: ServiceResponse<SearchUsersResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("UserManagement error code:", json.error);
                    dispatch(updateUserStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("UserManagement error: empty payload");
                    dispatch(updateUserStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateUserStateBuilder(
                    json.payload.users,
                    json.payload.count
                ));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(updateUserStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const UpdateUsersStatusActionName = 'UpdateUsersStatusAction';

export interface UpdateUsersStatusAction extends Action {
    status: ProcessStatus;
}

const updateUserStatusActionBuilder = (status: ProcessStatus): UpdateUsersStatusAction => ({
    type: UpdateUsersStatusActionName,
    status,
});

// Update state
export const UpdateUsersStateActionName = 'UpdateUsersStateAction';

export interface UpdateUsersStateAction extends Action {
    users: User[];
    count: number;
    status: ProcessStatus;
}

export const updateUserStateBuilder = (users: User[], count: number, status: ProcessStatus = ProcessStatus.Success): UpdateUsersStateAction => ({
    type: UpdateUsersStateActionName,
    users,
    count,
    status
});