import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {updateMeService} from "../book/services/UpdateMeService";

export const callUpdateMe = (password: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setUpdateMeStatusActionBuilder(ProcessStatus.InProgress));

        updateMeService(getState().user.token as string, {password})
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("Update me error code:", json.error);
                    dispatch(setUpdateMeStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setUpdateMeStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Update me generic error:", err);
                dispatch(setUpdateMeStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetUpdateMeStatusActionName = 'SetUpdateMeStatusAction';

export interface SetUpdateMeStatusAction extends Action {
    status: ProcessStatus;
}

export const setUpdateMeStatusActionBuilder = (status: ProcessStatus): SetUpdateMeStatusAction => ({
    type: SetUpdateMeStatusActionName,
    status,
});