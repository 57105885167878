import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { callMe } from "../../action/MeAction";
import { ProcessStatus } from "../../entity/ProcessStatus";
import { AppState } from '../../entity/state/AppState';
import { Routing, RoutingProps } from "./Routing";
import {callSearchAppConfiguration} from "../../action/SearchAppConfigurationsAction";

export const RoutingContainer = connect(
    (appState: AppState) => {
        return {
            isLogged: appState.user.expiredAt && appState.user.expiredAt > new Date().getUTCDate(),
            isUser: appState.user.meStatus !== ProcessStatus.None
        } as RoutingProps;
    },
    (dispatch: ThunkDispatch<AppState, undefined, Action>): RoutingProps => {
        return {
            loadUserInfo: () => dispatch(callMe()),
            loadAppConfigurations: ()=>dispatch(callSearchAppConfiguration())
        } as RoutingProps;
    }
)(Routing);