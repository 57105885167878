import {Action} from "redux";

export const SetSearchProductsFiltersActionName = "SetSearchProductsFiltersAction";

export interface SetSearchProductsFiltersAction extends Action {
    selectedFilterValues: { [category: string]: string };
    search: string | undefined;
    releaseType: number|undefined;
}

export const setSearchProductsFiltersActionBuilder = (selectedFilterValues: { [category: string]: string }, search: string | undefined, releaseType: number|undefined): SetSearchProductsFiltersAction => {
    return {
        type: SetSearchProductsFiltersActionName,
        selectedFilterValues,
        search,
        releaseType
    }
}