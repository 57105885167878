import { Action, Dispatch } from "redux";
import { deleteMetadataService } from "../book/services/DeleteMetadataService";
import { AppState } from "../entity/state/AppState";
import { ProcessStatus } from "../entity/ProcessStatus";
import { ServiceResponse } from "../entity/ServiceResponse";
import { evaluateErrorAction } from "./EvaluateErrorAction";

export const callDeleteMetadata = (metadataId: string) => {
    return (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
        dispatch(SetDeleteMetadataStatusActionBuilder(ProcessStatus.InProgress));

        return deleteMetadataService(getState().user.token as string, { metadataId })
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MetadataManagement error code:", json.error);
                    dispatch(SetDeleteMetadataStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(SetDeleteMetadataStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(SetDeleteMetadataStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Delete status
export const SetDeleteMetadataStatusActionName = 'SetDeleteMetadataStatusAction';

export interface SetDeleteMetadataStatusAction extends Action {
    status: ProcessStatus;
}

export const SetDeleteMetadataStatusActionBuilder = (status: ProcessStatus): SetDeleteMetadataStatusAction => ({
    type: SetDeleteMetadataStatusActionName,
    status,
});