import {initialAppState} from "../store/InitialAppState";
import {Action} from "redux";
import {MonitoringState} from "../entity/state/MonitoringState";
import {
    SetMonitoringProductPropertyCategoryChartStateAction,
    SetMonitoringProductPropertyCategoryChartStateActionName,
    SetMonitoringProductPropertyCategoryChartStatusAction,
    SetMonitoringProductPropertyCategoryChartStatusActionName
} from "../action/MonitoringProductPropertyCategoryChartAction";
import {
    SetMonitoringProductMonthChartStateAction,
    SetMonitoringProductMonthChartStateActionName,
    SetMonitoringProductMonthChartStatusAction,
    SetMonitoringProductMonthChartStatusActionName
} from "../action/MonitoringProductMonthChartAction";

export const monitoringReducer = (state: MonitoringState = initialAppState.monitoringState, a: Action): MonitoringState => {
    switch (a.type) {
        case SetMonitoringProductPropertyCategoryChartStatusActionName:
            const setMonitoringStatusAction = a as SetMonitoringProductPropertyCategoryChartStatusAction;
            const newMetadataStatus = {...state.productPropertyCategoryStatus};

            newMetadataStatus[setMonitoringStatusAction.category] = setMonitoringStatusAction.status
            return {
                ...state,
                productPropertyCategoryStatus: newMetadataStatus
            } as MonitoringState;
        case SetMonitoringProductPropertyCategoryChartStateActionName:
            const setMonitoringStateAction = a as SetMonitoringProductPropertyCategoryChartStateAction;
            const newMetadata = {...state.productPropertyCategoryData};
            const newMetadataStatus01 = {...state.productPropertyCategoryStatus};

            newMetadata[setMonitoringStateAction.category] = setMonitoringStateAction.items;
            newMetadataStatus01[setMonitoringStateAction.category] = setMonitoringStateAction.status

            return {
                ...state,
                productPropertyCategoryData: newMetadata,
                productPropertyCategoryStatus: newMetadataStatus01
            } as MonitoringState;

        case SetMonitoringProductMonthChartStatusActionName:
            const setMonitoringProductMonthChartStatusAction = a as SetMonitoringProductMonthChartStatusAction;

            return {
                ...state,
                productMonthStatus: setMonitoringProductMonthChartStatusAction.status
            } as MonitoringState;
        case SetMonitoringProductMonthChartStateActionName:
            const setMonitoringProductMonthChartStateAction = a as SetMonitoringProductMonthChartStateAction;

            return {
                ...state,
                productMonthData: setMonitoringProductMonthChartStateAction.items,
                productMonthStatus: setMonitoringProductMonthChartStateAction.status
            } as MonitoringState;

    }

    return state;
}