import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {fetchService} from "../FetchService";
import {AppConfiguration} from "../../entity/AppConfiguration";

export const searchAppConfigurationService = (authenticationToken: string, request: SearchAppConfigurationsRequest): Promise<ServiceResponse<SearchAppConfigurationsResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/appConfiguration', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface SearchAppConfigurationsRequest {
}

export interface SearchAppConfigurationsResponse {
    configurations: AppConfiguration[];
}