import {Settings} from "../Settings";
import {fetchService} from "../FetchService";
import {AppConfiguration} from "../../entity/AppConfiguration";

export const updateAppConfigurationsService = (authenticationToken: string, request: UpdateAppConfigurationRequest) => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/appConfiguration', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface UpdateAppConfigurationRequest {
    configurations: AppConfiguration[];
}