import { Action, Dispatch } from "redux";
import { updateMetadataService } from "../book/services/UpdateMetadataService";
import { AppState } from "../entity/state/AppState";
import { ProcessStatus } from "../entity/ProcessStatus";
import { ServiceResponse } from "../entity/ServiceResponse";
import { evaluateErrorAction } from "./EvaluateErrorAction";

export const callUpdateMetadata = (metadataId: string, category: string, value: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setUpdateMetadataStatusActionBuilder(ProcessStatus.InProgress));

        updateMetadataService(getState().user.token as string, { metadataId, category, value })
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MetadataManagement error code:", json.error);
                    dispatch(setUpdateMetadataStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setUpdateMetadataStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setUpdateMetadataStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetUpdateMetadataStatusActionName = 'SetUpdateMetadataStatusAction';

export interface SetUpdateMetadataStatusAction extends Action {
    status: ProcessStatus;
}

export const setUpdateMetadataStatusActionBuilder = (status: ProcessStatus): SetUpdateMetadataStatusAction => ({
    type: SetUpdateMetadataStatusActionName,
    status,
});