import {Action} from "redux";
import {initialAppState} from '../store/InitialAppState';
import {SetUpdateMeStatusAction, SetUpdateMeStatusActionName} from "../action/UpdateMeAction";
import {ProfileState} from "../entity/state/ProfileState";

export const profileReducer = (state: ProfileState = initialAppState.profile, a: Action): ProfileState => {
    switch (a.type) {
        case SetUpdateMeStatusActionName:
            const setUpdateMeStatusAction = a as SetUpdateMeStatusAction;
            return {
                ...state,
                updateMeStatus: setUpdateMeStatusAction.status
            };
    }

    return state;
}