import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {alpha} from "@material-ui/core/styles/colorManipulator";

export const useStyles = makeStyles((theme) => createStyles({
    environmentSticker: {
        position: 'fixed',
        zIndex: 5000,
        left: 'calc(50% - 75px)',
        right: 'calc(50% - 75px)',
        top: 0,
        padding: 4,
        width: 150,
        textAlign: 'center',
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        // Text
        fontWeight: 500,
        color: 'white',
        fontSize: '.95em',
        // Glass effect
        border: '1px solid rgba(255, 255, 255, 0.3)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(8px)'
    },
    local: {
        backgroundColor: alpha('#ffa500', 0.2)
    },
    qa: {
        backgroundColor: alpha('#9F2B68', 0.2)
    },
    prod: {},
    dev: {
        backgroundColor: alpha('#7FFF00', 0.2)
    },
}));