import {Action, Dispatch} from "redux";
import {GetProductResponse} from "../book/services/GetProductService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {deleteProductService} from "../book/services/DeleteProductService";

export const callDeleteProduct = (productId: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateDeleteProductStatusActionBuilder(ProcessStatus.InProgress));

        deleteProductService(getState().user.token as string, { productId })
            .then((json: ServiceResponse<GetProductResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("DeleteProduct error code:", json.error);
                    dispatch(updateDeleteProductStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("DeleteProduct error: empty payload");
                    dispatch(updateDeleteProductStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateDeleteProductStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic DeleteProduct error:", err);
                dispatch(updateDeleteProductStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const UpdateDeleteProductStatusActionName = 'UpdateDeleteProductStatusAction';

export interface UpdateDeleteProductStatusAction extends Action {
    status: ProcessStatus;
}

export const updateDeleteProductStatusActionBuilder = (status: ProcessStatus): UpdateDeleteProductStatusAction => ({
    type: UpdateDeleteProductStatusActionName,
    status
});