import { MetadataManagementState } from "../entity/state/MetadataManagementState";
import { ProcessStatus } from "../entity/ProcessStatus";

export const initialMetadataManagementState = {
    metadataCategories: [],
    metadataCategoriesStatus: ProcessStatus.None,

    metadata: {},
    metadataStatus: {},
    count: {},

    updateStatus: ProcessStatus.None,
    deleteStatus: ProcessStatus.None
} as MetadataManagementState