import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {
    MonitoringProductPropertyCategoryChartResponse,
    monitoringProductPropertyCategoryChartService
} from "../book/services/MonitoringProductPropertyCategoryChartService";
import {ValueCount} from "../entity/ValueCount";

export const callMonitoringProductPropertyCategoryChart = (category: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setMonitoringProductPropertyCategoryChartStatusActionBuilder(ProcessStatus.InProgress, category));

        monitoringProductPropertyCategoryChartService(getState().user.token as string, {category})
            .then((json: ServiceResponse<MonitoringProductPropertyCategoryChartResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MonitoringManagement error code:", json.error);
                    dispatch(setMonitoringProductPropertyCategoryChartStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                if (!json.payload) {
                    console.error("MonitoringManagement error: empty payload");
                    dispatch(setMonitoringProductPropertyCategoryChartStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                dispatch(setMonitoringProductPropertyCategoryChartStatusActionBuilder(ProcessStatus.Success, category));
                dispatch(setMonitoringProductPropertyCategoryChartStateBuilder(
                    json.payload.items,
                    category,
                ));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setMonitoringProductPropertyCategoryChartStatusActionBuilder(ProcessStatus.Error, category));
            });
    }
}

// Update status
export const SetMonitoringProductPropertyCategoryChartStatusActionName = 'SetMonitoringProductPropertyCategoryChartStatusAction';

export interface SetMonitoringProductPropertyCategoryChartStatusAction extends Action {
    status: ProcessStatus;
    category: string;
}

const setMonitoringProductPropertyCategoryChartStatusActionBuilder = (status: ProcessStatus, category: string): SetMonitoringProductPropertyCategoryChartStatusAction => ({
    type: SetMonitoringProductPropertyCategoryChartStatusActionName,
    status,
    category
});

// Update state
export const SetMonitoringProductPropertyCategoryChartStateActionName = 'SetMonitoringProductPropertyCategoryChartStateAction';

export interface SetMonitoringProductPropertyCategoryChartStateAction extends Action {
    items: ValueCount[];
    category: string;
    status: ProcessStatus;
}

export const setMonitoringProductPropertyCategoryChartStateBuilder = (items: ValueCount[], category: string, status: ProcessStatus = ProcessStatus.Success): SetMonitoringProductPropertyCategoryChartStateAction => ({
    type: SetMonitoringProductPropertyCategoryChartStateActionName,
    items,
    category,
    status
});