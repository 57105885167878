import { Settings } from "../Settings";
import {fetchService} from "../FetchService";

export const deleteMetadataService = (authenticationToken: string, request: DeleteMetadataRequest) => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/metadata', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface DeleteMetadataRequest {
    metadataId: string;
}