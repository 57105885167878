import {Action, Dispatch} from "redux";
import {LoginResponse, loginService} from "../book/services/LoginService";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";

export const callLogin = (email: string, password: string) => {
    return (dispatch: Dispatch) => {
        dispatch(updateLoginStatusActionBuilder(ProcessStatus.InProgress));

        loginService({username: email, password})
            .then((json: ServiceResponse<LoginResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("Login error code:", json.error);
                    dispatch(updateLoginStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("Login error: empty payload");
                    dispatch(updateLoginStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateLoginStatusActionBuilder(ProcessStatus.Success));
                dispatch(updateLoginStateBuilder(
                    json.payload.token,
                    json.payload.expiredAt,
                ));
            })
            .catch((err: any) => {
                console.error("Generic login error:", err);
                dispatch(updateLoginStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const UpdateLoginStatusActionName = 'UpdateLoginStatusAction';

export interface UpdateLoginStatusAction extends Action {
    loginStatus: ProcessStatus;
}

const updateLoginStatusActionBuilder = (status: ProcessStatus): UpdateLoginStatusAction => ({
    type: UpdateLoginStatusActionName,
    loginStatus: status
});

// Update state
export const UpdateLoginStateActionName = 'UpdateLoginStateAction';

export interface UpdateLoginStateAction extends Action {
    token: string;
    expiredAt: number;
    loginStatus: ProcessStatus;
}

export const updateLoginStateBuilder = (token: string, expiredAt: number, status: ProcessStatus = ProcessStatus.Success): UpdateLoginStateAction => ({
    type: UpdateLoginStateActionName,
    token,
    expiredAt,
    loginStatus: status
});