import React, {Fragment, FunctionComponent} from "react";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import {UserRole} from "../../../entity/UserRole";
import {Page} from "../../../entity/Page";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import AppsIcon from "@material-ui/icons/Apps";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {useTranslation} from "react-i18next";
// import {useStyles} from "./ResponsiveDrawer.css";
import {DrawerListItem} from "./DrawerListItem";
import {useSelector} from "react-redux";
import {AppState} from "../../../entity/state/AppState";
import SettingsIcon from '@material-ui/icons/Settings';

export const ResponsiveDrawer: FunctionComponent = () => {
    //const classes = useStyles();
    const {t} = useTranslation();
    const userRole = useSelector((state: AppState) => state.user.role);

    return <div>
        <List>
            {userRole >= UserRole.User &&<Fragment>
                    <DrawerListItem to={Page.ProductsSearch}
                                    title={t("Products")}>
                        <ListItemIcon><SearchIcon/></ListItemIcon>
                        <ListItemText primary={t("Products")}/>
                    </DrawerListItem>
                {/*<DrawerListItem to={Page.Monitoring}*/}
                {/*                title={t("Monitoring")}>*/}
                {/*    <ListItemIcon><EqualizerIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={t("Monitoring")}/>*/}
                {/*</DrawerListItem>*/}
                </Fragment>}
            {userRole > UserRole.Dev && <Fragment>
                <DrawerListItem to={Page.UsersManagement}
                                title={t("Users management")}>
                    <ListItemIcon><PersonIcon/></ListItemIcon>
                    <ListItemText primary={t("Users management")}/>
                </DrawerListItem>
                <DrawerListItem to={Page.MetadataManagement}
                                title={t("Metadata management")}>
                    <ListItemIcon><AppsIcon/></ListItemIcon>
                    <ListItemText primary={t("Metadata management")}/>
                </DrawerListItem>
                <DrawerListItem to={Page.AppConfigurations}
                                title={t("Configurations")}>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary={t("Configurations")}/>
                </DrawerListItem>
            </Fragment>}
            <Divider/>
            <List>
                <DrawerListItem to={Page.Credits}
                                title={t("Credits")}>
                    <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                    <ListItemText primary={t("Credits")}/>
                </DrawerListItem>
            </List>
        </List>
    </div>;
};