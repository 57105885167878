import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {exportProductsService} from "../book/services/ExportProductsService";
import {resetUserStateBuilder} from "./ResetUserStateAction";

export const callExportProductsAction = (search?: string, productPropertyFilterIds?: string[], releaseType?: number | undefined) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateExportProductStatusActionBuilder(ProcessStatus.InProgress));

        const authenticationToken = getState().user.token;

        if (!authenticationToken) {
            dispatch(resetUserStateBuilder());
            return;
        }

        return exportProductsService(authenticationToken, {
            search,
            productPropertyFilterIds,
            releaseType
        }).then(response => {
            const url = window.URL.createObjectURL(response);
            const hiddenHypertextLink = document.createElement('a');
            hiddenHypertextLink.href = url;
            hiddenHypertextLink.download = "export.csv";
            document.body.appendChild(hiddenHypertextLink);
            hiddenHypertextLink.click();
            hiddenHypertextLink.remove();
            dispatch(updateExportProductStatusActionBuilder(ProcessStatus.Success));
        }).catch((err: any) => {
            console.error("Generic login error:", err);
            dispatch(updateExportProductStatusActionBuilder(ProcessStatus.Error));
        });
    };
};

export const UpdateExportProductStatusActionName = 'UpdateExportProductStatusAction';

export interface UpdateExportProductStatusAction extends Action {
    status: ProcessStatus;
}

export const updateExportProductStatusActionBuilder = (status: ProcessStatus): UpdateExportProductStatusAction => ({
    type: UpdateExportProductStatusActionName,
    status
});