import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {AppConfiguration} from '../entity/AppConfiguration';
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {
    searchAppConfigurationService,
    SearchAppConfigurationsResponse
} from "../book/services/SearchAppConfigurationService";

export const callSearchAppConfiguration = () => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setAppConfigurationStatusActionBuilder(ProcessStatus.InProgress));

        searchAppConfigurationService(getState().user.token as string, { })
            .then((json: ServiceResponse<SearchAppConfigurationsResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("AppConfigurationManagement error code:", json.error);
                    dispatch(setAppConfigurationStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("AppConfigurationManagement error: empty payload");
                    dispatch(setAppConfigurationStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setAppConfigurationStateBuilder(
                    json.payload.configurations,
                ));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setAppConfigurationStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetAppConfigurationStatusActionName = 'SetAppConfigurationStatusAction';

export interface SetAppConfigurationStatusAction extends Action {
    status: ProcessStatus;
}

const setAppConfigurationStatusActionBuilder = (status: ProcessStatus): SetAppConfigurationStatusAction => ({
    type: SetAppConfigurationStatusActionName,
    status,
});

// Update state
export const SetAppConfigurationStateActionName = 'SetAppConfigurationStateAction';

export interface SetAppConfigurationStateAction extends Action {
    configurations: AppConfiguration[];
    status: ProcessStatus;
}

export const setAppConfigurationStateBuilder = (configurations: AppConfiguration[], status: ProcessStatus=ProcessStatus.Success): SetAppConfigurationStateAction => ({
    type: SetAppConfigurationStateActionName,
    configurations,
    status
});