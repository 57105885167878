import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {fetchService} from "../FetchService";
import {ValueCount} from "../../entity/ValueCount";

export const monitoringProductPropertyCategoryChartService = (authenticationToken: string, request: MonitoringProductPropertyCategoryChartRequest): Promise<ServiceResponse<MonitoringProductPropertyCategoryChartResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/monitoring/product-property-by-category', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface MonitoringProductPropertyCategoryChartRequest {
    category: string;
}

export interface MonitoringProductPropertyCategoryChartResponse {
    items: ValueCount[];
}

