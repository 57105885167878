import { ServiceResponse } from "../../entity/ServiceResponse";
import { Metadata } from '../../entity/Metadata';
import { Settings } from "../Settings";
import { PaginatedRequest } from '../../entity/PaginatedRequest';
import {fetchService} from "../FetchService";

export const searchMetadataService = (authenticationToken: string, request: SearchMetadataRequest): Promise<ServiceResponse<SearchMetadataResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/metadata', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface SearchMetadataResponse {
    metadata: Metadata[];
    count: number;
}

export interface SearchMetadataRequest extends PaginatedRequest {
    category: string;
    search?: string;
}