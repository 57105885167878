import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { callLogin } from "../../../action/LoginAction";
import { Login, LoginProps } from "./Login";
import { AppState } from '../../../entity/state/AppState';

export const LoginContainer = connect(
    (appState: AppState): LoginProps => {
        return {
            status: appState.user.loginStatus,
            isLogged: !!appState.user.token,
            appName: appState.appName
        } as LoginProps;
    },
    (dispatch: ThunkDispatch<AppState, undefined, Action>): LoginProps => {
        return {
            onLoginClick: (email, password) => {
                dispatch(callLogin(email, password));
            }
        } as LoginProps;
    }
)(Login);