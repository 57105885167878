import {Action, Dispatch} from "redux";
import {SearchMetadataResponse, searchMetadataService} from "../book/services/SearchMetadataService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {Metadata} from '../entity/Metadata';
import {evaluateErrorAction} from "./EvaluateErrorAction";

export const callSearchMetadata = (category: string, search?: string, page?: number | null, pageSize?: number | null) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setMetadataStatusActionBuilder(ProcessStatus.InProgress, category));

        searchMetadataService(getState().user.token as string, { category, search, page, pageSize })
            .then((json: ServiceResponse<SearchMetadataResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MetadataManagement error code:", json.error);
                    dispatch(setMetadataStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                if (!json.payload) {
                    console.error("MetadataManagement error: empty payload");
                    dispatch(setMetadataStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                dispatch(setMetadataStateBuilder(
                    json.payload.metadata,
                    category,
                    json.payload.count,
                ));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setMetadataStatusActionBuilder(ProcessStatus.Error, category));
            });
    }
}

// Update status
export const SetMetadataStatusActionName = 'SetMetadataStatusAction';

export interface SetMetadataStatusAction extends Action {
    status: ProcessStatus;
    category: string;
}

const setMetadataStatusActionBuilder = (status: ProcessStatus, category: string): SetMetadataStatusAction => ({
    type: SetMetadataStatusActionName,
    status,
    category
});

// Update state
export const SetMetadataStateActionName = 'SetMetadataStateAction';

export interface SetMetadataStateAction extends Action {
    metadata: Metadata[];
    category: string;
    count: number;
    status: ProcessStatus;
}

export const setMetadataStateBuilder = (metadata: Metadata[], category: string, count: number, status: ProcessStatus=ProcessStatus.Success): SetMetadataStateAction => ({
    type: SetMetadataStateActionName,
    metadata,
    category,
    count,
    status
});