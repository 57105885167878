import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            display: 'flex',
            paddingBottom: '100px',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#fff',
            },
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        toolbar: theme.mixins.toolbar,
        children: {
            padding: '30px 20px',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: '15px 10px',
                boxShadow: 'none'
            },
        },
        title: {
            marginBottom: '20px',
            marginLeft: '5px',
            display: 'inline-block',
        },
        actions: {
            float: 'right'
        },
        divider: {
            margin: '0px 0 30px!important'
        }
    })
);