import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";

export const drawerWidth = 270;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: theme.mixins.toolbar,
        appTitle: {
            display: 'inline-block',
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            fontSize: '1.3em',
            fontWeight: 700,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawer: {
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1,
            },
        },
    }),
);