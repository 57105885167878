import React, {FunctionComponent} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import {useSelector} from "react-redux";
import {AppState} from "../../../entity/state/AppState";
import {PageTemplate} from "../pagetemplate/PageTemplate";
import {Loading} from "./Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
            overflow: 'hidden',
            textAlign: 'center',
        },
        loading: {
            '& .message': {
                display: 'block',
                marginTop: '20px',
                fontSize: '1.5em',
                fontWeight: 200
            }
        }
    })
);

export const LoadingPage: FunctionComponent = () => {
    const classes = useStyles();
    const userRole = useSelector((appState: AppState) => appState.user.role);

    return <PageTemplate pageName={'Loading'} userRole={userRole} hideDrawerButton={true}>
        <main className={classes.content}>
            <Loading message="Please wait..."
                     className={classes.loading}
                     circularProgressSize='4em'/>
        </main>
    </PageTemplate>;
}