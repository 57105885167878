import {PaginatedRequest} from "../../entity/PaginatedRequest";
import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {Product} from "../../entity/Product";
import {fetchService} from "../FetchService";

export const getProductService = (authenticationToken: string, request: GetProductRequest): Promise<ServiceResponse<GetProductResponse>> => {
    return fetchService(`${Settings.backEndBaseUrl()}/api/v1/product?productId=${request.productId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
    });
};

export interface GetProductRequest extends PaginatedRequest {
    productId: string;
}

export interface GetProductResponse {
    product: Product;
}
