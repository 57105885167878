import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {
    SearchProductPropertyFiltersResponse,
    searchProductPropertyFiltersService
} from "../book/services/SearchProductPropertyFiltersService";
import {ProductProperty} from "../entity/ProductProperty";

export const searchFilters = (category: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateFiltersStatusActionBuilder(ProcessStatus.InProgress, category));

        searchProductPropertyFiltersService(getState().user.token as string, {category})
            .then((json: ServiceResponse<SearchProductPropertyFiltersResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("Search product property filters error code:", json.error);
                    dispatch(updateFiltersStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                if (!json.payload) {
                    console.error("Search product property filters error: empty payload");
                    dispatch(updateFiltersStatusActionBuilder(ProcessStatus.Error, category));
                    return;
                }

                dispatch(updateFiltersStateBuilder(json.payload.productPropertyFilters, category));
            })
            .catch((err: any) => {
                console.error("Search product property filters - Generic error:", err);
                dispatch(updateFiltersStatusActionBuilder(ProcessStatus.Error, category));
            });
    }
}

// Update status
export const UpdateFiltersStatusActionName = 'UpdateFiltersStatusAction';

export interface UpdateFiltersStatusAction extends Action {
    status: ProcessStatus;
    category: string;
}

const updateFiltersStatusActionBuilder = (status: ProcessStatus, category: string): UpdateFiltersStatusAction => ({
    type: UpdateFiltersStatusActionName,
    status,
    category
});

// Update state
export const UpdateFiltersStateActionName = 'UpdateFiltersStateAction';

export interface UpdateFiltersStateAction extends Action {
    filters: ProductProperty[];
    category: string;
    status: ProcessStatus;
}

export const updateFiltersStateBuilder = (filters: ProductProperty[], category: string, status: ProcessStatus = ProcessStatus.Success): UpdateFiltersStateAction => ({
    type: UpdateFiltersStateActionName,
    filters,
    category,
    status
});