import {Action, Dispatch} from "redux";
import {MeResponse, meService} from "../book/services/MeService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {resetUserStateBuilder} from "./ResetUserStateAction";

export const callMe = () => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateMeStatusActionBuilder(ProcessStatus.InProgress));

        meService(getState().user.token as string)
            .then((json: ServiceResponse<MeResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("Me error code:", json.error);
                    dispatch(updateMeStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("Me error: empty payload");
                    dispatch(updateMeStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateMeStateBuilder(
                    json.payload.username,
                    json.payload.role,
                ));
            })
            .catch((err: any) => {
                console.error("Generic login error:", err);
                dispatch(updateMeStatusActionBuilder(ProcessStatus.Error));
                dispatch(resetUserStateBuilder());
                window.location.reload();
            });
    }
}

// Update status
export const UpdateMeStatusActionName = 'UpdateMeStatusAction';

export interface UpdateMeStatusAction extends Action {
    status: ProcessStatus;
}

const updateMeStatusActionBuilder = (status: ProcessStatus): UpdateMeStatusAction => ({
    type: UpdateMeStatusActionName,
    status
});

// Update state
export const UpdateMeStateActionName = 'UpdateMeStateAction';

export interface UpdateMeStateAction extends Action {
    username: string;
    role: string;
    status: ProcessStatus;
}

export const updateMeStateBuilder = (email: string, role: string, status: ProcessStatus = ProcessStatus.Success): UpdateMeStateAction => ({
    type: UpdateMeStateActionName,
    username: email,
    role,
    status
});