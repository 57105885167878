import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import React, {FunctionComponent, useState} from "react";
import {Redirect, useLocation} from "react-router-dom";
import {ProcessStatus} from "../../../entity/ProcessStatus";
import {Loading} from "../../common/loading/Loading";
import {useStyles} from "./Login.css";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import {Input, InputAdornment, InputLabel} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Login: FunctionComponent<LoginProps> = props => {
    const {t} = useTranslation();
    const classes = useStyles();
    const query = useQuery();
    const [state, setState] = useState<LoginState>({
        showPassword: false,
        password: '',
        username: ''
    });

    if (props.isLogged) {
        const redirectTo = query.get("redirect-to");
        if (redirectTo) {
            return <Redirect to={{pathname: redirectTo}}/>;
        }

        return <Redirect to="/"/>;
    }

    const handleClickShowPassword = () => {
        setState(state => ({...state, showPassword: !state.showPassword}));
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return <div className={classes.login}>
        <Paper className={classes.main} elevation={6}>
            {props.status === ProcessStatus.InProgress && <Loading message="Login…"/>}

            {props.status !== ProcessStatus.InProgress && <React.Fragment>
                <div className={classes.companyLogo}>
                    <img src="carel-login.png" alt={t(props.appName)}/>
                </div>

                <Typography variant="h4" className={classes.title}>
                    {t(props.appName)}
                </Typography>
                <Typography variant="h5" className={classes.subtitle}>
                    {t("Login")}
                </Typography>

                <form onSubmit={() => props.onLoginClick(state.username, state.password)}>
                    <TextField
                        required
                        value={state.username}
                        onChange={e => setState(state => ({...state, username: e.target.value}))}
                        label="Email"
                        margin="normal"
                        fullWidth/>

                    <FormControl fullWidth required={true}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type={state.showPassword ? 'text' : 'password'}
                            value={state.password}
                            fullWidth
                            onChange={e => setState(state => ({...state, password: e.target.value}))}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}>
                                        {state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }/>
                    </FormControl>

                    <Button
                        disabled={!state.username || !state.password}
                        className={classes.loginButton}
                        variant="contained"
                        type="submit"
                        color="primary">
                        Login
                    </Button>
                </form>

                {props.status === ProcessStatus.Error &&
                    <Box color="error.main"
                         className={classes.error}>{t("Error while login, please check email and password and retry.")}</Box>}
            </React.Fragment>}
        </Paper>
    </div>;
}

export interface LoginProps {
    isLogged: boolean;
    onLoginClick: (username: string, password: string) => void;
    status: ProcessStatus;
    appName: string;
}

interface LoginState {
    username: string;
    password: string;
    showPassword: boolean;
}