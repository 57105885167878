import Divider from '@material-ui/core/Divider/Divider';
import Drawer from '@material-ui/core/Drawer/Drawer';
import Hidden from '@material-ui/core/Hidden/Hidden';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import React, {Fragment, FunctionComponent, memo} from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {ResponsiveDrawer} from "./ResponsiveDrawer";
import {useStyles} from "./CDrawer.css";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../entity/state/AppState";
import {toggleDrawerSizeActionBuilder} from "../../../action/ToggleDrawerSizeAction";

const InternalCDrawer: FunctionComponent<CDrawerProps> = props => {
    const {t} = useTranslation();
    const {window} = props;
    const classes = useStyles();
    const theme = useTheme();
    const container = window !== undefined ? () => window().document.body : undefined;
    const expandedMenu = useSelector((state: AppState) => state.user.expandedMenu);
    const dispatch = useDispatch();
    const toggleDrawerSize = () => {
        dispatch(toggleDrawerSizeActionBuilder());
    }

    return <nav className={classes.drawer}>
        <Hidden xsUp implementation="css">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={props.isMobileDrawerOpen}
                onClose={props.handleDrawerToggle}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                <div className={classes.toolbar}>
                   <span className={classes.appTitle}>Governance Tool</span>
                </div>
                <Divider/>
                <ResponsiveDrawer/>
            </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
            <Drawer
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: expandedMenu,
                    [classes.drawerClose]: !expandedMenu,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: expandedMenu,
                        [classes.drawerClose]: !expandedMenu,
                    }),
                }}
                variant="permanent"
                open>
                <div className={classes.toolbar} />
                <Divider/>
                <ResponsiveDrawer/>
                <Divider/>
                <List>
                    <ListItem button onClick={() => toggleDrawerSize()}>
                        <ListItemIcon title={expandedMenu ? t("Reduce menu") : t("Expand menu")}>
                            {!expandedMenu && <Fragment>
                                <KeyboardArrowRightIcon/>
                            </Fragment>}
                            {expandedMenu && <Fragment>
                                <KeyboardArrowLeftIcon/>
                            </Fragment>}
                        </ListItemIcon>
                        <ListItemText primary={t("Reduce menu")}/>
                    </ListItem>
                </List>
            </Drawer>
        </Hidden>
    </nav>
}

export const CDrawer = memo(InternalCDrawer, (prev, next) => {
    return !!prev && prev.isMobileDrawerOpen === next.isMobileDrawerOpen;
});

export interface CDrawerProps {
    window?: () => Window;
    handleDrawerToggle: () => void;
    isMobileDrawerOpen: boolean;
}