import {initialAppState} from "../store/InitialAppState";
import {Action} from "redux";
import {AppConfigurationsState} from "../entity/state/AppConfigurationsState";
import {
    SetUpdateAppConfigurationStatusAction,
    SetUpdateAppConfigurationStatusActionName
} from "../action/UpdateAppConfigurationsAction";
import {
    SetAppConfigurationStateAction,
    SetAppConfigurationStateActionName, SetAppConfigurationStatusAction, SetAppConfigurationStatusActionName
} from "../action/SearchAppConfigurationsAction";

export const appConfigurationsReducer = (state: AppConfigurationsState = initialAppState.appConfigurationsState, a: Action): AppConfigurationsState => {
    switch (a.type) {
        case SetUpdateAppConfigurationStatusActionName:
            return {
                ...state,
                updateAppConfigurationStatus: (a as SetUpdateAppConfigurationStatusAction).status
            }
        case SetAppConfigurationStateActionName:
            return {
                ...state,
                configurations: (a as SetAppConfigurationStateAction).configurations,
                appConfigurationsStatus: (a as SetAppConfigurationStateAction).status
            }
        case SetAppConfigurationStatusActionName:
            return {
                ...state,
                appConfigurationsStatus: (a as SetAppConfigurationStatusAction).status
            }
    }

    return state;
}