import {FunctionComponent} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

export const useStyles = makeStyles(() =>
    createStyles({
        notFound: {
            flexGrow: 1,
            "height": "100%",
            "width": "100%",
            "backgroundSize": "cover",
            "paddingTop": "80px"
        },
        main: {
            "maxWidth": "1600px",
            "overflow": "hidden",
            "width": "90%",
            "margin": "0px auto",
            "fallbacks": [
                {
                    "maxWidth": "750px"
                },
                {
                    "overflow": "hidden"
                }
            ],
            marginTop: "80px",
            paddingTop: "16px",
            paddingLeft: "16px",
            marginBottom: "80px",
            paddingRight: "16px",
            paddingBottom: "16px",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            borderRadius: "4px",
            color: "rgba(0, 0, 0, 0.87)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#fff"
        },
        title: {
            "textAlign": "center",
            "fontSize": "6rem",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontWeight: 300,
            "lineHeight": "1.167",
            "letterSpacing": "-0.01562em",
            "margin": "0"
        },
        subtitle: {
            "textAlign": "center",
            "fontSize": "3rem",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontWeight: 400,
            "lineHeight": "1.167",
            "letterSpacing": "0em",
            "margin": "0"
        },
        content: {
            "textAlign": "center",
            "marginTop": "30px"
        },
        goHomeButton: {
            display: "inline-block",
            marginTop: "30px",
            marginBottom: "20px",
            color: "#fff",
            backgroundColor: "#ed1c24",
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            padding: "6px 16px",
            fontSize: "0.875rem",
            minWidth: "64px",
            boxSizing: "border-box",
            transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontWeight: 500,
            lineHeight: "1.75",
            borderRadius: "4px",
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
            border: "0",
            cursor: "pointer",
            outline: "0",
            position: "relative",
            alignItems: "center",
            userSelect: "none",
            verticalAlign: "middle",
            mozAppearance: "none",
            justifyContent: "center",
            textDecoration: "none",
            webkitAppearance: "none",
            webkitTapHighlightColor: "transparent"
        }
    })
);

export const PageNotFound: FunctionComponent = () => {
    const classes = useStyles();

    return <div className={classes.notFound}>
        <div className={classes.main}>
            <h1 className={classes.title}>
                404
            </h1>
            <h3 className={classes.subtitle}>
                Page not found
            </h3>

            <div className={classes.content}>
                <div>
                    The page you are looking for was moved, removed, renamed or might never existed.
                </div>

                <a href="/" className={classes.goHomeButton}>
                    go home
                </a>
            </div>

        </div>
    </div>
}