import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {
    MonitoringProductPropertyCategoryChartResponse
} from "../book/services/MonitoringProductPropertyCategoryChartService";
import {ValueCount} from "../entity/ValueCount";
import {monitoringProductMonthChartService} from "../book/services/MonitoringProductMonthChartService";

export const callMonitoringProductMonthChartChart = () => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setMonitoringProductMonthChartStatusActionBuilder(ProcessStatus.InProgress));

        monitoringProductMonthChartService(getState().user.token as string, {})
            .then((json: ServiceResponse<MonitoringProductPropertyCategoryChartResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MonitoringManagement error code:", json.error);
                    dispatch(setMonitoringProductMonthChartStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("MonitoringManagement error: empty payload");
                    dispatch(setMonitoringProductMonthChartStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setMonitoringProductMonthChartStateBuilder(json.payload.items));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setMonitoringProductMonthChartStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetMonitoringProductMonthChartStatusActionName = 'SetMonitoringProductMonthChartStatusAction';

export interface SetMonitoringProductMonthChartStatusAction extends Action {
    status: ProcessStatus;
}

const setMonitoringProductMonthChartStatusActionBuilder = (status: ProcessStatus): SetMonitoringProductMonthChartStatusAction => ({
    type: SetMonitoringProductMonthChartStatusActionName,
    status
});

// Update state
export const SetMonitoringProductMonthChartStateActionName = 'SetMonitoringProductMonthChartStateAction';

export interface SetMonitoringProductMonthChartStateAction extends Action {
    items: ValueCount[];
    status: ProcessStatus;
}

export const setMonitoringProductMonthChartStateBuilder = (items: ValueCount[], status: ProcessStatus = ProcessStatus.Success): SetMonitoringProductMonthChartStateAction => ({
    type: SetMonitoringProductMonthChartStateActionName,
    items,
    status
});