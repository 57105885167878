import { MetadataCategory } from "../../entity/MetadataCategory";
import { ServiceResponse } from "../../entity/ServiceResponse";
import { Settings } from "../Settings";
import {fetchService} from "../FetchService";

export const searchMetadataCategoryService = (authenticationToken: string): Promise<ServiceResponse<SearchMetadataCategoryResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/metadata/category', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify({})
    });
};

export interface SearchMetadataCategoryResponse {
    metadataCategories: MetadataCategory[];
}