import {ServiceResponse} from "../entity/ServiceResponse";
import {AppError} from "../entity/AppError";

export function fetchService(input: RequestInfo, init?: RequestInit): Promise<ServiceResponse<any>>{
    return fetch(input, init)
        .then(res => {
            if (res.status === AppError.UNAUTHORIZED) {
                return {error: AppError.UNAUTHORIZED};
            }

            return res.json();
        });
}

export function fetchBlobService(input: RequestInfo, init?: RequestInit): Promise<Blob> {
    return fetch(input, init)
        .then(res => {
            if (res.status === AppError.UNAUTHORIZED) {
                throw new Error('Unauthorized request');
            }

            return res.blob();
        });
}