import {connect} from "react-redux";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../entity/state/AppState";
import {AppbarUserMenu, AppbarUserMenuProps} from "./AppbarUserMenu";
import {resetUserStateBuilder} from "../../../action/ResetUserStateAction";

export const AppbarUserMenuContainer = connect(
    (appState: AppState) => {
        return {
            username: appState.user.username,
            meStatus: appState.user.meStatus
        } as AppbarUserMenuProps;
    },
    (dispatch: ThunkDispatch<AppState, undefined, Action>) => {
        return {
            onSignOutClick: () => {
                dispatch(resetUserStateBuilder());
            }
        } as AppbarUserMenuProps;
    },
)(AppbarUserMenu);