import React, {Fragment, FunctionComponent, useState} from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button/Button";
import {EnFlag} from "./EnFlag";
import {ItFlag} from "./ItFlag";

class LanguageCodes {
    static IT = "it";
    static EN = "en";
}

const FlagImage: FunctionComponent<FlagImageProps> = props => {
    const width = 37;
    const height = 25;
    const classname = 'lang-flag';

    switch (props.language) {
        case LanguageCodes.EN:
            return <EnFlag height={height} width={width} className={classname}/>;
        case LanguageCodes.IT:
            return <ItFlag height={height} width={width} className={classname}/>;
        default:
            return null;
    }
};

interface FlagImageProps {
    language: string;
}

const useStyles = makeStyles(() => createStyles({
    currentLanguageName:{
    },
    languageName: {
        paddingLeft: '10px'
    }
}));

export const LanguageMenu: FunctionComponent = () => {
    const classes = useStyles();
    const {i18n} = useTranslation()
    const currentLanguage = i18n.language;
    const supportedLanguages = ['it', 'en'];
    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<null | HTMLElement>(null);
    const langMenuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => setLangMenuAnchorEl(event.currentTarget);
    const langMenuHandleClose = (languageCode?: string) => {
        if (languageCode) {
            changeLanguage(languageCode);
        }
        setLangMenuAnchorEl(null);
    };

    const isLangMenuOpen = Boolean(langMenuAnchorEl);

    return <Fragment>
        <Button color="inherit"
                    className="change-language-menu-button"
                    aria-owns={isLangMenuOpen ? 'lang-menu' : undefined}
                    aria-haspopup="true"
                    onClick={langMenuHandleClick}>
            <span className={classes.currentLanguageName}>{currentLanguage.toUpperCase().substring(0, 2)}</span>
        </Button>
        <Menu
            anchorEl={langMenuAnchorEl}
            open={isLangMenuOpen}
            onClose={() => {
                langMenuHandleClose();
            }}
            PaperProps={{
                style: {
                    maxHeight: 48 * 4.5,
                    width: 200,
                },
            }}>
            {supportedLanguages.map(language => <MenuItem key={language} onClick={() => {
                langMenuHandleClose(language);
            }} selected={language === currentLanguage.substring(0, 2)}>
                <FlagImage language={language}/>
                <span className={classes.languageName}>{language.toUpperCase()}</span>
            </MenuItem>)}
        </Menu>
    </Fragment>;
}