import {Action} from "redux";
import {SetDeleteMetadataStatusAction, SetDeleteMetadataStatusActionName} from "../action/DeleteMetadataAction";
import {
    SetMetadataStateAction,
    SetMetadataStateActionName,
    SetMetadataStatusAction,
    SetMetadataStatusActionName
} from "../action/SearchMetadataAction";
import {
    SetMetadataCategoryStateAction,
    SetMetadataCategoryStateActionName,
    SetMetadataCategoryStatusAction,
    SetMetadataCategoryStatusActionName
} from "../action/SearchMetadataCategoryAction";
import {SetUpdateMetadataStatusAction, SetUpdateMetadataStatusActionName} from "../action/UpdateMetadataAction";
import {MetadataManagementState} from "../entity/state/MetadataManagementState";
import {initialAppState} from "../store/InitialAppState";
import {ProcessStatus} from "../entity/ProcessStatus";

export const metadataManagementReducer = (state: MetadataManagementState = initialAppState.metadataManagement, a: Action): MetadataManagementState => {
    switch (a.type) {
        case SetMetadataCategoryStatusActionName:
            const updateMetadataManagementCategoryStatusAction = a as SetMetadataCategoryStatusAction;

            return {
                ...state,
                metadataCategoriesStatus: updateMetadataManagementCategoryStatusAction.status
            } as MetadataManagementState;
        case SetMetadataCategoryStateActionName:
            const updateMetadataCategoryStateAction = a as SetMetadataCategoryStateAction;

            return {
                ...state,
                metadataCategories: updateMetadataCategoryStateAction.metadataCategories,
                metadataCategoriesStatus: updateMetadataCategoryStateAction.status
            } as MetadataManagementState;
        case SetMetadataStatusActionName:
            const updateMetadataStatusAction = a as SetMetadataStatusAction;
            const newMetadataStatus = {...state.metadataStatus};

            newMetadataStatus[updateMetadataStatusAction.category] = updateMetadataStatusAction.status
            return {
                ...state,
                metadataStatus: newMetadataStatus
            } as MetadataManagementState;
        case SetMetadataStateActionName:
            const updateMetadataManagementStateAction = a as SetMetadataStateAction;
            const newMetadata = {...state.metadata};
            const newCount = {...state.count};
            const newMetadataStatus01 = {...state.metadataStatus};

            newMetadata[updateMetadataManagementStateAction.category] = updateMetadataManagementStateAction.metadata
            newCount[updateMetadataManagementStateAction.category] = updateMetadataManagementStateAction.count
            newMetadataStatus01[updateMetadataManagementStateAction.category] = updateMetadataManagementStateAction.status

            return {
                ...state,
                metadata: newMetadata,
                count: newCount,
                metadataStatus: newMetadataStatus01
            } as MetadataManagementState;
        case SetUpdateMetadataStatusActionName:
            const setUpdateMetadataStatusAction = a as SetUpdateMetadataStatusAction;
            const newState01 = setUpdateMetadataStatusAction.status === ProcessStatus.Success ? initialAppState.metadataManagement : state;
            return {
                ...newState01,
                updateStatus: setUpdateMetadataStatusAction.status,
            };
        case SetDeleteMetadataStatusActionName:
            const setDeleteMetadataStatusAction = a as SetDeleteMetadataStatusAction;
            const newState02 = setDeleteMetadataStatusAction.status === ProcessStatus.Success ? initialAppState.metadataManagement : state;
            return {
                ...newState02,
                deleteStatus: setDeleteMetadataStatusAction.status
            };
    }

    return state;
}