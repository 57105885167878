import { Settings } from "../Settings";
import {fetchService} from "../FetchService";

export const updateMetadataService = (authenticationToken: string, request: UpdateMetadataRequest) => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/metadata', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface UpdateMetadataRequest {
    metadataId: string;
    category: string;
    value: string;
}