import {FunctionComponent} from "react";
import {useStyles} from "./EnvironmentSticker.css";

export const EnvironmentSticker:FunctionComponent=()=>{
    const classes = useStyles();
    const url=window.location.href;
    const environment=((()=>{
        if(url.indexOf("-qa")!==-1){
            return ["QA", classes.qa];
        }

        if(url.indexOf("windows.net")!==-1){
            return ["DEV", classes.dev];
        }

        if(url.indexOf("localhost")!==-1 || url.indexOf("127.0.0.1")!==-1){
            return ["LOCAL", classes.local];
        }

        return undefined; //["PROD", classes.prod];
    })());

    if(environment) {
        return <div className={`${classes.environmentSticker} ${environment[1]}`}>{environment[0]}</div>;
    }

    return null;
}