import { ProcessStatus } from "../entity/ProcessStatus";
import { SearchProductsState } from "../entity/state/SearchProductsState";

export const initialSearchProductsState: SearchProductsState = {
    selectedMetadata: [],
    filterValues: {},
    filterValuesStatus: {},
    count: 0,
    executionTime: 0,
    productsFound: [],
    status: ProcessStatus.None,
    deleteStatus: ProcessStatus.None,
    exportStatus: ProcessStatus.None,
    pagination: {
        currentPage: 0,
        pageSize: 50
    },
    filters:{
        selectedFilterValues: {}
    }
}