import { ServiceResponse } from "../../entity/ServiceResponse";
import { Settings } from "../Settings";

export const loginService = (request: LoginRequest): Promise<ServiceResponse<LoginResponse>> => {
    return fetch(Settings.backEndBaseUrl() + '/api/v1/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(res => res.json());
};

export interface LoginResponse {
    token: string;
    expiredAt: number;
}

export interface LoginRequest {
    username: string;
    password: string;
}