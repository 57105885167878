import { applyMiddleware, compose, createStore, Store } from "redux";
import { AppState } from "../entity/state/AppState";
import { appReducer } from "../reducer/AppReducer";
import { initialAppState } from "./InitialAppState";
import thunk from 'redux-thunk';

const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ as any;

export const appStore: Store<AppState> = createStore(
    appReducer,
    initialAppState,
    compose(
        applyMiddleware(thunk),
        devToolsExtension ? devToolsExtension() : (f: any) => f
    )
);