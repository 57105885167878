import {Settings} from "../Settings";
import {fetchBlobService} from "../FetchService";

export const exportProductsService = (authenticationToken: string, request: ExportProductsRequest): Promise<Blob> => {
    return fetchBlobService(Settings.backEndBaseUrl() + '/api/v1/product/export', {
        method: 'POST',
        headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface ExportProductsRequest {
    search?: string;
    productPropertyFilterIds?: string[];
    releaseType?: number
}
