import { ProcessStatus } from "../entity/ProcessStatus";
import { UsersManagementState } from "../entity/state/UsersManagementState";

export const initialUsersManagementState = {
    users: [],
    count: 0,
    status: ProcessStatus.None,

    updateStatus: ProcessStatus.None,
    deleteStatus: ProcessStatus.None
} as UsersManagementState