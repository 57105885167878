import { Action, Dispatch } from "redux";
import { deleteUserService } from "../book/services/DeleteUserService";
import { AppState } from "../entity/state/AppState";
import { ProcessStatus } from "../entity/ProcessStatus";
import { ServiceResponse } from "../entity/ServiceResponse";
import { evaluateErrorAction } from "./EvaluateErrorAction";

export const callDeleteUser = (userId: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateDeleteUserStatusActionBuilder(ProcessStatus.InProgress));

        deleteUserService(getState().user.token as string, { userId })
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("UserManagement error code:", json.error);
                    dispatch(updateDeleteUserStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateDeleteUserStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(updateDeleteUserStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Delete status
export const UpdateDeleteUserStatusActionName = 'UpdateDeleteUserStatusAction';

export interface UpdateDeleteUserStatusAction extends Action {
    status: ProcessStatus;
}

export const updateDeleteUserStatusActionBuilder = (status: ProcessStatus): UpdateDeleteUserStatusAction => ({
    type: UpdateDeleteUserStatusActionName,
    status,
});