import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import React, {Fragment, FunctionComponent, lazy, Suspense, useEffect} from "react";
import {Route} from 'react-router';
import {HashRouter, Redirect, RouteProps, Switch} from 'react-router-dom';
import {LoginContainer} from '../page/login/LoginContainer';
import {Page} from '../../entity/Page';
import {LoadingPage} from "../common/loading/LoadingPage";
import {PageNotFound} from "../page/page-not-found/PageNotFound";

export const Routing: FunctionComponent<RoutingProps> = props => {
    const {isUser, isLogged, loadUserInfo, loadAppConfigurations} = props;
    useEffect(() => {
        if (!isUser && isLogged) {
            loadUserInfo();
            loadAppConfigurations();
        }
    }, [isUser, isLogged, loadUserInfo, loadAppConfigurations]);

    const ProtectedRoute = (p: RouteProps) => {
        if (!props.isLogged) {
            const renderComponent = () => (<Redirect to={`${Page.Login}?redirect-to=${p.location?.pathname}`}/>);
            return <Route {...p} component={renderComponent} render={undefined}/>;
        }

        return <Route {...p} />;
    }

    const SearchProducts = lazy(() => import("../page/searchproducts/SearchProductsContainer"));
    const UsersManagement = lazy(() => import("../page/usersmanagement/UsersManagementContainer"));
    const AppConfigurations = lazy(() => import("../page/app-configurations/AppConfigurations"));
    const MetadataManagement = lazy(() => import("../page/metadatamanagement/MetadataManagementContainer"));
    const ProductDetails = lazy(() => import("../page/productdetails/ProductDetailsContainer"));
    const Credits = lazy(() => import('../page/credits/CreditsContainer'));
    const Profile = lazy(() => import('../page/profile/ProfileContainer'));
    const Monitoring = lazy(() => import('../page/monitoring/MonitoringContainer'));

    return <Fragment>
        <CssBaseline/>
        <HashRouter>
            <Suspense fallback={<LoadingPage/>}>
                <Switch>
                    <ProtectedRoute exact path={Page.ProductsSearch} component={() => <SearchProducts/>}/>
                    <ProtectedRoute exact path={Page.Profile} component={() => <Profile/>}/>
                    <ProtectedRoute exact path={Page.UsersManagement} component={UsersManagement}/>
                    <ProtectedRoute exact path={Page.MetadataManagement} component={MetadataManagement}/>
                    <ProtectedRoute exact path={Page.ProductDetails} component={ProductDetails}/>
                    <ProtectedRoute exact path={Page.Credits} component={Credits}/>
                    <ProtectedRoute exact path={Page.Monitoring} component={Monitoring}/>
                    <ProtectedRoute exact path={Page.AppConfigurations} component={AppConfigurations}/>
                    <Route path={Page.Login} component={LoginContainer}/>
                    <Route component={PageNotFound} />
                </Switch>
            </Suspense>
        </HashRouter>
    </Fragment>;
};

export interface RoutingProps {
    isLogged: boolean;
    isUser: boolean;
    loadUserInfo: () => void;
    loadAppConfigurations: () => void;
}