import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import React, {FunctionComponent} from "react";
import {CAppBar} from "../c-appbar/CAppBar";
import {useStyles} from "./PageTemplate.css";
import Hidden from "@material-ui/core/Hidden";
import {useSelector} from "react-redux";
import {AppState} from "../../../entity/state/AppState";
import {useTranslation} from "react-i18next";
import {CDrawer} from "../c-drawer/CDrawer";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

export const PageTemplate: FunctionComponent<PageTemplateProps> = props => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState<PageTemplateState>({mobileOpen: false, eeCounter: 0});
    const appName = t(useSelector((state: AppState) => state.appName));

    const handleDrawerToggle = () => {
        setState(state => ({...state, mobileOpen: !state.mobileOpen}));
    };

    const handleClose = () => {
        setState(state => ({...state, eeCounter: 0}));
    };

    return <div className={classes.page}>
        <CAppBar appName={appName}
                 showBackButton={props.showBackButton}
                 pageName={props.pageName}
                 pageSubName={props.pageSubName}
                 pageActions={props.pageActions}
                 handleDrawerToggle={handleDrawerToggle}
                 hideDrawerButton={props.hideDrawerButton}/>

        <CDrawer handleDrawerToggle={handleDrawerToggle}
                 isMobileDrawerOpen={state.mobileOpen}/>

        <main className={classes.content}>
            <div className={classes.toolbar}/>

            <Hidden smDown>
                <Typography variant="h5" color="inherit" className={classes.title} onClick={() => setState(state => ({
                    ...state,
                    eeCounter: (state.eeCounter + 1) * (props.pageName === 'Credits' ? 1 : 0)
                }))}>
                    {props.pageName}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1" color="inherit" className={classes.title}>
                    {props.pageSubName}
                </Typography>

                <div className={classes.actions}>
                    {props.pageActions}
                </div>

                <Divider className={classes.divider}/>
            </Hidden>
            <Container maxWidth={props.pageMaxWidth ?? 'md'} disableGutters>
                <Paper className={classes.children}>
                    {props.children}
                </Paper>
            </Container>
        </main>

        <Dialog
            open={state.eeCounter >= 15}
            aria-labelledby="ssd-dialog-title"
            aria-describedby="ssd-dialog-description">
            <DialogTitle id="ssd-dialog-title">
                &#x49;&#x73;&#x20;&#x47;&#x6f;&#x76;&#x65;&#x72;&#x6e;&#x61;&#x6e;&#x63;&#x65;&#x20;&#x54;&#x6f;&#x6f;&#x6c;&#x20;&#x61;&#x6e;&#x20;&#x75;&#x73;&#x65;&#x66;&#x75;&#x6c;&#x20;&#x61;&#x70;&#x70;&#x6c;&#x69;&#x63;&#x61;&#x74;&#x69;&#x6f;&#x6e;&#x3f;
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="ssd-dialog-description">
                    &#x44;&#x6f;&#x6e;&#x27;&#x74;&#x20;&#x75;&#x73;&#x65;&#x20;&#x61;&#x20;&#x73;&#x6f;&#x66;&#x74;&#x77;&#x61;&#x72;&#x65;&#x20;&#x74;&#x6f;&#x20;&#x74;&#x65;&#x61;&#x63;&#x68;&#x20;&#x61;&#x20;&#x77;&#x6f;&#x72;&#x6b;&#x69;&#x6e;&#x67;&#x20;&#x6d;&#x65;&#x74;&#x68;&#x6f;&#x64;&#x2c;&#x20;&#x79;&#x6f;&#x75;&#x20;&#x68;&#x61;&#x76;&#x65;&#x20;&#x74;&#x6f;&#x20;&#x70;&#x6c;&#x61;&#x6e;&#x20;&#x61;&#x20;&#x74;&#x72;&#x61;&#x69;&#x6e;&#x69;&#x6e;&#x67;&#x20;&#x70;&#x72;&#x6f;&#x67;&#x72;&#x61;&#x6d;&#x20;&#x62;&#x61;&#x73;&#x65;&#x64;&#x20;&#x6f;&#x6e;&#x20;&#x63;&#x6f;&#x6d;&#x70;&#x61;&#x6e;&#x79;&#x20;&#x76;&#x61;&#x6c;&#x75;&#x65;&#x73;&#x20;&#x61;&#x6e;&#x64;&#x20;&#x6e;&#x65;&#x65;&#x64;&#x73;&#x2e;
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
}

export interface PageTemplateProps {
    pageName: string;
    pageSubName?: string;
    userRole: number;

    hideDrawerButton?: boolean;

    showBackButton?: boolean;

    pageMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

    pageActions?: React.ReactNode;
}

interface PageTemplateState {
    mobileOpen: boolean;
    eeCounter: number;
}