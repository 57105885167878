import AppBar from '@material-ui/core/AppBar/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, {Fragment, FunctionComponent, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {AppbarUserMenuContainer} from '../appbarusermenu/AppbarUserMenuContainer';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from "@material-ui/core/Hidden/Hidden";
import {LanguageMenu} from "../language-menu/LanguageMenu";
import {EnvironmentSticker} from "./EnvironmentSticker";
import {useStyles} from "./CAppBar.css";

const InternalCAppBar: FunctionComponent<ApplicationAppBarProps> = props => {
    const classes = useStyles();
    const history = useHistory();

    return <Fragment>
        <AppBar className={classes.appBar} color="primary">
            <Toolbar>
                {props.showBackButton && <IconButton
                    color="inherit"
                    title={props.backLabel ?? ''}
                    size="small"
                    aria-label={props.backLabel ?? ''}
                    onClick={() => {
                        history.goBack();
                    }}>
                    <ArrowBackIosIcon/>
                </IconButton>}

                {(!props.showBackButton && !props.hideDrawerButton) && <IconButton
                    color="inherit"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                    className={classes.menuButton}>
                    <MenuIcon/>
                </IconButton>}

                <Hidden smDown>
                    <img src="logo-icon.png" alt={props.appName} width="30" className={classes.icon}/>
                    <Typography variant="h6" color="inherit">
                        {props.appName}
                    </Typography>
                </Hidden>

                <Hidden mdUp>
                    <Typography variant="h6" color="inherit" className={classes.pageName}>
                        {props.pageName}
                    </Typography>
                    &nbsp;
                    <Typography variant="subtitle1" color="inherit" className={classes.pageSubName}>
                        {props.pageSubName}
                    </Typography>
                </Hidden>

                <div className={classes.grow}/>
                <div className={classes.actions}>
                    <LanguageMenu/>
                    {!!props.pageActions && <Hidden mdUp>
                        {props.pageActions}
                    </Hidden>}
                    <AppbarUserMenuContainer/>
                </div>
            </Toolbar>
        </AppBar>

        <EnvironmentSticker/>
    </Fragment>;
}

export const CAppBar = memo(InternalCAppBar, (prev, next) => {
    return !!prev &&
        prev.appName === next.appName &&
        prev.pageName === next.pageName &&
        prev.pageSubName === next.pageSubName &&
        prev.hideDrawerButton === next.hideDrawerButton &&
        prev.showBackButton === next.showBackButton &&
        prev.backLabel === next.backLabel;
});

export interface ApplicationAppBarProps {
    appName: string;
    pageName?: string;
    pageSubName?: string;

    hideDrawerButton?: boolean;

    showBackButton?: boolean;
    backLabel?: string;

    handleDrawerToggle: () => void;

    pageActions?: React.ReactNode;
}

