import {Settings} from "../Settings";
import {fetchService} from "../FetchService";

export const updateMeService = (authenticationToken: string, request: UpdateMeRequest) => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/user/me', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface UpdateMeRequest {
    password: string;
}