import { Settings } from "../Settings";
import {fetchService} from "../FetchService";

export const updateUserService = (authenticationToken: string, request: UpdateUserRequest) => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/user', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
}

export interface UpdateUserRequest {
    userId: string;
    password: string;
    role: string;
    username: string;
}