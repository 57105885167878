import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, {FunctionComponent} from "react";
import {ProcessStatus} from "../../../entity/ProcessStatus";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PersonIcon from '@material-ui/icons/Person';
import {Link} from "react-router-dom";
import {Page} from "../../../entity/Page";

export const AppbarUserMenu: FunctionComponent<AppbarUserMenuProps> = props => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem component={Link} to={Page.Profile}>
                <ListItemIcon>
                    <PersonIcon/>
                </ListItemIcon>
                <Typography variant="inherit">{t("Profile")}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                props.onSignOutClick();
                window.location.replace(Page.ProductsSearch);
                // window.location.reload();
            }}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <Typography variant="inherit">{t("Sign out")}</Typography>
            </MenuItem>
        </Menu>
    );
    const buttonProps = {
        title: props.meStatus !== ProcessStatus.Success ? t("Loading…") : props.username as string,
        onClick: handleProfileMenuOpen,
    };

    return <React.Fragment>
        <Hidden smDown>
            <Button {...buttonProps} startIcon={<AccountCircle/>} color='inherit'>
                {props.username}
            </Button>
        </Hidden>

        <Hidden mdUp>
            <IconButton {...buttonProps} color='inherit' style={{ marginRight: -12 }}>
                <AccountCircle/>
            </IconButton>
        </Hidden>

        {renderMenu}
    </React.Fragment>;
}

export interface AppbarUserMenuProps {
    username: string | null;
    meStatus: ProcessStatus;
    onSignOutClick: () => void;
}