import {combineReducers} from "redux";
import {AppState} from "../entity/state/AppState";
import {initialAppState} from '../store/InitialAppState';
import {userReducer} from "./UserReducer";
import {metadataManagementReducer} from "./MetadataManagementReducer";
import {searchProductsReducer} from "./SearchProductsReducer";
import {usersManagementReducer} from './UsersManagementReducer';
import {productDetailsReducer} from './ProductDetailsReducer';
import {profileReducer} from "./ProfileReducer";
import {monitoringReducer} from "./MonitoringReducer";
import {appConfigurationsReducer} from "./AppConfigurationsReducer";

export const appReducer = combineReducers<AppState>({
    appName: (state: string = initialAppState.appName) => (state),
    user: userReducer,
    searchProducts: searchProductsReducer,
    usersManagement: usersManagementReducer,
    metadataManagement: metadataManagementReducer,
    productDetails: productDetailsReducer,
    profile: profileReducer,
    monitoringState: monitoringReducer,
    appConfigurationsState: appConfigurationsReducer
});