import {Action, Dispatch} from "redux";
import {GetProductResponse, getProductService} from "../book/services/GetProductService";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {evaluateErrorAction} from "./EvaluateErrorAction";
import {Product} from "../entity/Product";

export const callGetProduct = (productId: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(updateGetProductStatusActionBuilder(ProcessStatus.InProgress));

        getProductService(getState().user.token as string, {productId})
            .then((json: ServiceResponse<GetProductResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("GetProduct error code:", json.error);
                    dispatch(updateGetProductStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("GetProduct error: empty payload");
                    dispatch(updateGetProductStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(updateGetProductStateBuilder(json.payload.product));
            })
            .catch((err: any) => {
                console.error("Generic getProduct error:", err);
                dispatch(updateGetProductStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const UpdateGetProductStatusActionName = 'UpdateGetProductStatusAction';

export interface UpdateGetProductStatusAction extends Action {
    status: ProcessStatus;
}

export const updateGetProductStatusActionBuilder = (status: ProcessStatus): UpdateGetProductStatusAction => ({
    type: UpdateGetProductStatusActionName,
    status
});

// Update state
export const UpdateGetProductStateActionName = 'UpdateGetProductStateAction';

export interface UpdateGetProductStateAction extends Action {
    product: Product;
    status: ProcessStatus;
}

export const updateGetProductStateBuilder = (product: Product, status: ProcessStatus = ProcessStatus.Success): UpdateGetProductStateAction => ({
    type: UpdateGetProductStateActionName,
    product,
    status
});