import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import {Theme} from "@material-ui/core/styles/createTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: alpha(theme.palette.primary.main, 0.9),
            backdropFilter: 'blur(20px)',
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        icon: {
            verticalAlign: 'middle',
            marginRight: '20px'
        },
        grow: {
            flexGrow: 1,
        },
        pageName:{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        pageSubName:{},
        actions: {
            display: 'flex',
        },
    })
);