import {AppState} from "../entity/state/AppState";
import {initialUserState} from './InitialUserState';
import {initialMetadataManagementState} from "./InitialMetadataManagementState";
import {initialSearchProductsState} from "./InitialSearchProductsState";
import {initialUsersManagementState} from './InitialUsersManagementState';
import {initialProductDetailsState} from './InitialProductDetailsState';
import {initialProfileState} from "./InitialProfileState";
import {initialMonitoringState} from "./InitialMonitoringState";
import {initialAppConfigurationsState} from "./InitialAppConfigurationsState";

export const initialAppState: AppState = {
    appName: "Governance Tool",
    user: initialUserState,
    searchProducts: initialSearchProductsState,
    usersManagement: initialUsersManagementState,
    metadataManagement: initialMetadataManagementState,
    productDetails: initialProductDetailsState,
    profile: initialProfileState,
    monitoringState: initialMonitoringState,
    appConfigurationsState: initialAppConfigurationsState
};