import React, {FunctionComponent} from "react";
import {Link, useLocation} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem/ListItem";

export const DrawerListItem: FunctionComponent<DrawerListItemProps> = (props) => {
    const location = useLocation();
    return <ListItem button
                     component={Link}
                     to={props.to}
                     selected={props.selected || props.to === location.pathname}
                     title={props.title}>
        {props.children}
    </ListItem>
}

interface DrawerListItemProps {
    to: string;
    title?: string;
    selected?: boolean;
}