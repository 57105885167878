export const UserRole = {
    Unknown: -1,
    User: 10,
    Dev: 20,
    SuperUser: 30,
    Admin: 40,
};

const UserRoleMap: { [key: string]: number } = {
    'User': UserRole.User,
    'Dev': UserRole.Dev,
    'SuperUser': UserRole.SuperUser,
    'Admin': UserRole.Admin,
};

export const UserRoleAdmin = 'Admin';

export const ToUserRole = (role: string | null): number => {
    return role ? UserRoleMap[role] as number : UserRole.Unknown;
}

export const ToUserRoleName = (role: number): string => {
    switch(role){
        case UserRole.User: return "User";
        case UserRole.Dev: return "Dev";
        case UserRole.SuperUser: return "Super user";
        case UserRole.Admin: return "Admin";
        default: return "Boh!";
    }
}