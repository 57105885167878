import {PaginatedRequest} from "../../entity/PaginatedRequest";
import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {Product} from "../../entity/Product";
import {fetchService} from "../FetchService";

export const deleteProductService = (authenticationToken: string, request: DeleteProductRequest): Promise<ServiceResponse<DeleteProductResponse>> => {
    return fetchService(`${Settings.backEndBaseUrl()}/api/v1/product`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface DeleteProductRequest extends PaginatedRequest {
    productId: string;
}

export interface DeleteProductResponse {
    product: Product;
}
