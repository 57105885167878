import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {FunctionComponent} from 'react';
import CarelLogo from "../../../image/CarelLogo.svg";

const useStyles = makeStyles(_ => createStyles({
    logo: {
        marginRight: '13px'
    }
}));

export const CarelImage: FunctionComponent<CarelLogoProps> = _ => {
    const classes = useStyles();

    return <img src={CarelLogo} alt="CAREL - Cloud Licenses Manager" className={classes.logo} width={75} />;
};

export interface CarelLogoProps {
}
