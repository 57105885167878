import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

export const useStyles = makeStyles(theme => createStyles({
    '@global': {
        body: {
            background: 'url(login-background.jpg) no-repeat left bottom fixed',
            backgroundSize: 'cover',
        }
    },
    login: {
        flexGrow: 1,
        width: '100%',
        paddingTop: "80px",
    },
    main: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        margin: "0px auto",
        paddingTop: theme.spacing() * 2,
        paddingBottom: theme.spacing() * 2,
        width: "90%",
        maxWidth: "450px",
        overflow: "hidden"
    },
    companyLogo: {
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '35px'
    },
    title: {
        textAlign: 'center'
    },
    subtitle: {
        textAlign: 'center',
        marginTop: 8
    },
    loginButton: {
        display: "block",
        marginTop: "1em",
        float: "right"
    },
    error: {
        display: "block",
        marginTop: "2em"
    }
}));