import { ServiceResponse } from "../../entity/ServiceResponse";
import { Settings } from "../Settings";
import {fetchService} from "../FetchService";

export const meService = (authenticationToken: string): Promise<ServiceResponse<MeResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/user/me', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        }
    });
};

export interface MeResponse {
    username: string;
    role: string;
}