import {Action, Dispatch} from "redux";
import {AppState} from "../entity/state/AppState";
import {ProcessStatus} from "../entity/ProcessStatus";
import {ServiceResponse} from "../entity/ServiceResponse";
import {
    SearchMetadataCategoryResponse,
    searchMetadataCategoryService
} from '../book/services/SearchMetadataCategoryService';
import {MetadataCategory} from '../entity/MetadataCategory';
import {evaluateErrorAction} from "./EvaluateErrorAction";

export const callSearchMetadataCategory = () => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setMetadataCategoryStatusActionBuilder(ProcessStatus.InProgress));

        searchMetadataCategoryService(getState().user.token as string)
            .then((json: ServiceResponse<SearchMetadataCategoryResponse>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("SearchMetadataCategory error code:", json.error);
                    dispatch(setMetadataCategoryStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                if (!json.payload) {
                    console.error("SearchMetadataCategory error: empty payload");
                    dispatch(setMetadataCategoryStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setMetadataCategoryStateBuilder(json.payload.metadataCategories));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setMetadataCategoryStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetMetadataCategoryStatusActionName = 'SetMetadataCategoryStatusAction';

export interface SetMetadataCategoryStatusAction extends Action {
    status: ProcessStatus;
}

const setMetadataCategoryStatusActionBuilder = (status: ProcessStatus): SetMetadataCategoryStatusAction => ({
    type: SetMetadataCategoryStatusActionName,
    status,
});

// Update state
export const SetMetadataCategoryStateActionName = 'SetMetadataCategoryStateAction';

export interface SetMetadataCategoryStateAction extends Action {
    metadataCategories: MetadataCategory[];
    status: ProcessStatus;
}

export const setMetadataCategoryStateBuilder = (metadataCategories: MetadataCategory[], status: ProcessStatus = ProcessStatus.Success): SetMetadataCategoryStateAction => ({
    type: SetMetadataCategoryStateActionName,
    metadataCategories,
    status
});