import {PaginatedRequest} from "../../entity/PaginatedRequest";
import {TimedServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {Product} from "../../entity/Product";
import {fetchService} from "../FetchService";

export const searchProductsService = (authenticationToken: string, request: SearchProductsRequest): Promise<TimedServiceResponse<SearchProductsResponse>> => {
    const start = window.performance.now();
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/product', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    }).then(response=>{
        const end = window.performance.now();
        return {...response, executionTime: end - start}
    });
};

export interface SearchProductsRequest extends PaginatedRequest {
    search?: string;
    productPropertyFilterIds?: string[];
    categories?: string[];
    releaseType?: number;
}

export interface SearchProductsResponse {
    products: Product[];
    count: number;
}