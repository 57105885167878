import { ProcessStatus } from '../entity/ProcessStatus';
import { UserRole } from '../entity/UserRole';
import { UserState } from '../entity/state/UserState';
import {SessionStorage} from "../book/SessionStorage";
import {SessionStorageKey} from "../book/SessionStorageKey";

export const initialUserState: UserState = {
    meStatus: ProcessStatus.None,
    username: null,
    role: UserRole.Unknown,
    expandedMenu: true,
    expiredAt: SessionStorage.getInt(SessionStorageKey.expiredAt),
    loginStatus: ProcessStatus.None,
    token: SessionStorage.getString(SessionStorageKey.token)
};