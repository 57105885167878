import {ServiceResponse} from "../../entity/ServiceResponse";
import {Settings} from "../Settings";
import {ProductProperty} from "../../entity/ProductProperty";
import {fetchService} from "../FetchService";

export const searchProductPropertyFiltersService = (authenticationToken: string, request: SearchProductPropertyFiltersRequest): Promise<ServiceResponse<SearchProductPropertyFiltersResponse>> => {
    return fetchService(Settings.backEndBaseUrl() + '/api/v1/product/property/filter', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authenticationToken
        },
        body: JSON.stringify(request)
    });
};

export interface SearchProductPropertyFiltersRequest {
    category: string;
}

export interface SearchProductPropertyFiltersResponse {
    productPropertyFilters: ProductProperty[];
}