export const Page = {
    Login: '/login',
    MetadataManagement: '/metadata-management',
    UsersManagement: '/users-management',
    ProductsSearch: '/',
    Profile: '/profile',
    ProductDetails: '/product/:productId',
    Monitoring: '/monitoring',
    Credits: '/credits',
    AppConfigurations: '/app-configurations'
}