import { Action, Dispatch } from "redux";
import { updateUserService } from "../book/services/UpdateUserService";
import { AppState } from "../entity/state/AppState";
import { ProcessStatus } from "../entity/ProcessStatus";
import { ServiceResponse } from "../entity/ServiceResponse";
import { evaluateErrorAction } from "./EvaluateErrorAction";

export const callUpdateUser = (userId: string, username: string, password: string, role: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setUpdateUserStatusActionBuilder(ProcessStatus.InProgress));

        updateUserService(getState().user.token as string, { userId, role, password, username })
            .then((json: ServiceResponse<{}>) => {
                if (json.error) {
                    evaluateErrorAction(json.error, dispatch);

                    console.error("MetadataManagement error code:", json.error);
                    dispatch(setUpdateUserStatusActionBuilder(ProcessStatus.Error));
                    return;
                }

                dispatch(setUpdateUserStatusActionBuilder(ProcessStatus.Success));
            })
            .catch((err: any) => {
                console.error("Generic error:", err);
                dispatch(setUpdateUserStatusActionBuilder(ProcessStatus.Error));
            });
    }
}

// Update status
export const SetUpdateUserStatusActionName = 'SetUpdateUserStatusAction';

export interface SetUpdateUserStatusAction extends Action {
    status: ProcessStatus;
}

export const setUpdateUserStatusActionBuilder = (status: ProcessStatus): SetUpdateUserStatusAction => ({
    type: SetUpdateUserStatusActionName,
    status,
});