import { Action } from "redux";
import { UpdateDeleteUserStatusAction, UpdateDeleteUserStatusActionName } from "../action/DeleteUserAction";
import { UpdateUsersStateAction, UpdateUsersStateActionName, UpdateUsersStatusAction, UpdateUsersStatusActionName } from "../action/SearchUsersAction";
import { SetUpdateUserStatusAction, SetUpdateUserStatusActionName } from "../action/UpdateUserAction";
import { UsersManagementState } from "../entity/state/UsersManagementState";
import { initialAppState } from "../store/InitialAppState";

export const usersManagementReducer = (state: UsersManagementState = initialAppState.usersManagement, a: Action): UsersManagementState => {
    switch (a.type) {
        case UpdateUsersStatusActionName:
            const updateUsersStatusAction = a as UpdateUsersStatusAction;
            return {
                ...state,
                status: updateUsersStatusAction.status
            } as UsersManagementState;
        case UpdateUsersStateActionName:
            const updateUsersManagementStateAction = a as UpdateUsersStateAction;
            return {
                ...state,
                users: updateUsersManagementStateAction.users,
                count: updateUsersManagementStateAction.count,
                status: updateUsersManagementStateAction.status
            } as UsersManagementState;
        case SetUpdateUserStatusActionName:
            return {
                ...state,
                updateStatus: (a as SetUpdateUserStatusAction).status
            };
        case UpdateDeleteUserStatusActionName:
            return {
                ...state,
                deleteStatus: (a as UpdateDeleteUserStatusAction).status
            };
    }

    return state;
}