export class SessionStorage {
    public static getBoolean = (key: string, defaultValue: boolean | null = null): boolean | null => {
        const value = sessionStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }

        return value === true.toString();
    }

    public static getInt = (key: string, defaultValue: number | null = null): number | null => {
        const value = sessionStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }

        return parseInt(value);
    }

    public static getFloat = (key: string, defaultValue: number | null = null): number | null => {
        const value = sessionStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }

        return parseFloat(value);
    }

    public static getString = (key: string, defaultValue: string | null = null): string | null => sessionStorage.getItem(key) ?? defaultValue;

    public static setInt = (key: string, value: number) => sessionStorage.setItem(key, value.toString());

    public static setFloat = (key: string, value: number) => sessionStorage.setItem(key, value.toString());

    public static setBoolean = (key: string, value: boolean) => sessionStorage.setItem(key, value.toString());

    public static setString = (key: string, value: string) => sessionStorage.setItem(key, value);

    public static clear = () => sessionStorage.clear();

    public static removeItem = (key: string) => sessionStorage.removeItem(key);
}