import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles(() => createStyles({
    loading: {
        margin: '0 auto',
        textAlign: 'center',
        maxWidth: '300px',

        '& .progress': {
            verticalAlign: 'middle',
            display: 'inline-block',
            marginRight: '15px'
        }
    },
}));

export const Loading: FunctionComponent<LoadingProps> = props => {
    const classes = useStyles();

    return <div className={`${classes.loading} ${props.className ?? ''}`}>
        <span className="progress"><CircularProgress size={props.circularProgressSize} /></span>
        <span className="message">{props.message}</span>
    </div>;
}

Loading.defaultProps = {
    message: 'Loading…'
};

export interface LoadingProps {
    message?: string;
    className?: string;
    circularProgressSize?: string | number;
}