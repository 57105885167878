import {Action} from "redux";
import {SearchProductsState} from "../entity/state/SearchProductsState";
import {initialAppState} from "../store/InitialAppState";
import {
    UpdateSearchProductsStateAction,
    UpdateSearchProductsStateActionName,
    UpdateSearchProductsStatusAction,
    UpdateSearchProductsStatusActionName
} from "../action/SearchProductsAction";
import {
    UpdateFiltersStateAction,
    UpdateFiltersStateActionName, UpdateFiltersStatusAction,
    UpdateFiltersStatusActionName
} from "../action/SearchProductPropertyFiltersAction";
import {UpdateDeleteProductStatusAction, UpdateDeleteProductStatusActionName} from "../action/DeleteProductAction";
import {UpdateExportProductStatusAction, UpdateExportProductStatusActionName} from "../action/ExportProductsAction";
import {
    SetSearchProductsPaginationAction,
    SetSearchProductsPaginationActionName
} from "../action/SetSearchProductsPaginationAction";
import {
    SetSearchProductsFiltersAction,
    SetSearchProductsFiltersActionName
} from "../action/SetSearchProductsFiltersAction";

export const searchProductsReducer = (state: SearchProductsState = initialAppState.searchProducts, a: Action): SearchProductsState => {
    switch (a.type) {
        case UpdateFiltersStatusActionName:
            const updateSearchProductMetadataStatusAction = a as UpdateFiltersStatusAction;
            const newMetadataStatus = {...state.filterValuesStatus};

            newMetadataStatus[updateSearchProductMetadataStatusAction.category] = updateSearchProductMetadataStatusAction.status
            return {
                ...state,
                filterValuesStatus: newMetadataStatus
            } as SearchProductsState;
        case UpdateFiltersStateActionName:
            const updateSearchProductMetadataStateAction = a as UpdateFiltersStateAction;
            const newMetadata = {...state.filterValues};
            const newMetadataStatus01 = {...state.filterValuesStatus};

            newMetadata[updateSearchProductMetadataStateAction.category] = updateSearchProductMetadataStateAction.filters
            newMetadataStatus01[updateSearchProductMetadataStateAction.category] = updateSearchProductMetadataStateAction.status

            return {
                ...state,
                filterValues: newMetadata,
                filterValuesStatus: newMetadataStatus01
            } as SearchProductsState;
        case UpdateSearchProductsStatusActionName:
            return {
                ...state,
                status: (a as UpdateSearchProductsStatusAction).status
            };
        case UpdateSearchProductsStateActionName:
            const updateSearchProductsStateAction = a as UpdateSearchProductsStateAction;
            return {
                ...state,
                count: updateSearchProductsStateAction.count,
                productsFound: updateSearchProductsStateAction.foundProducts,
                executionTime: updateSearchProductsStateAction.executionTime,
                status: updateSearchProductsStateAction.status
            };
        case UpdateDeleteProductStatusActionName:
            return {
                ...state,
                deleteStatus: (a as UpdateDeleteProductStatusAction).status
            };
        case UpdateExportProductStatusActionName:
            return {
                ...state,
                exportStatus: (a as UpdateExportProductStatusAction).status
            };
        case SetSearchProductsPaginationActionName:
            const updateSearchProductsPaginationAction = a as SetSearchProductsPaginationAction;
            return {
                ...state,
                pagination: {
                    pageSize: updateSearchProductsPaginationAction.pageSize,
                    currentPage: updateSearchProductsPaginationAction.currentPage
                }
            }
        case SetSearchProductsFiltersActionName:
            const setSearchProductsFiltersAction = a as SetSearchProductsFiltersAction;
            return {
                ...state,
                filters: {
                    search: setSearchProductsFiltersAction.search,
                    selectedFilterValues: setSearchProductsFiltersAction.selectedFilterValues,
                    releaseType: setSearchProductsFiltersAction.releaseType
                }
            };
    }

    return state;
}